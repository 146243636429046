import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css'

import { AndroidLaunch } from './AndroidLaunch';
import { DownloadFromAppStore } from './DownloadFromAppStore';

const root = ReactDOM.createRoot(document.getElementById('root'));

const isIOSorMac = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|macintosh/.test(userAgent);
};

if (isIOSorMac()) {
  root.render(
    <DownloadFromAppStore />
  )
} else {
  root.render(
    <AndroidLaunch />
  );
}