import React from 'react';
import image from './assets/03-2.png';
import logo from './assets/logo_p_gradient.svg';

export const AndroidLaunch = () => {
    return (
        <div
            style={{
                margin: 0,
                padding: 0,
                backgroundColor: '#ffffff',
                display: 'flex',
                flex: 1,
                width: '100vw'
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    flexDirection: 'column',
                }}>
                <img
                    src={logo}
                    alt="PARennial Golf"
                    style={{
                        maxWidth: 30,
                        height: 'auto',
                        width: '30%',
                    }}
                />
                <img
                    src={image}
                    alt="Get the app"
                    style={{
                        maxWidth: 300,
                        minWidth: 200,
                        width: '25%',
                        height: 'auto'
                    }}
                />
                <div style={{ padding: 20 }}>
                    <h5 style={{ fontWeight: 'bold' }}>
                        Now available for Android
                    </h5>
                    <p style={{ color: '#737373' }}>
                        The PARennial Golf App is now in beta and available immediately to Android users. Please reach out for access.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        Call: <a href="tel:3123136218" style={{ fontWeight: 'normal', color: '#2dd67d' }}>312-313-6218</a>
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        Email: <a href="mailto:info@parennialgolf.com" style={{ fontWeight: 'normal', color: '#2dd67d' }}>org@parennialgolf.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};